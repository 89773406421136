import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Route, Switch } from "react-router-dom";
import { LazyLoadedNotFoundPage, LazyLoadedSharePage } from "./routes";
import { AppRoutes } from "./routes/AppRoutes";

const App = () => {
  return (
    <div className="w-screen h-screen overflow-x-hidden font-nunito-sans bg-secondary">
      <Switch>
        <Route path={AppRoutes.sharer} exact>
          <LazyLoadedSharePage />
        </Route>
        <Route>
          <LazyLoadedNotFoundPage />
        </Route>
      </Switch>
      <ToastContainer />
    </div>
  );
};

export default App;
